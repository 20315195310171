<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Select Client') }}</h3>
                </div>
                <div class="form-group">
                    <Autocomplete 
                        @input="getCompanies"
                        @onSelect="selected"
                        debounce="500"
                        :placeholder="$t('Search for client')"
                        :results="companies"
                        input-class="form-control"
                        results-container-class="autocomplete-results-container"
                        results-item-class="autocomplete-results-item"
                    />
                </div>

                <button @click="$emit('closeModal')" class="btn btn-secondary">{{ $t('Cancel') }}</button>
            </div>
        </template>
    </Modal>
</template>

<script>
import Autocomplete from 'vue3-autocomplete'
import {vacancyService} from "@/services/vacancyService";
import Modal from "@/components/widgets/Modal";

export default {
    name: "ModalSelectCompanyComponent",
    data() {
        return {
            companies: []
        }
    },
    emits: ['selected'],
    methods: {
        selected(selected) {
            this.$emit('selected', selected);
            this.$emit('closeModal');
        },
        getCompanies(query) {
            if (query.length) {
                vacancyService.getCompanies(query).then(response => this.companies = response.data.data)
            }
        }
    },
    components: {
        Autocomplete,
        Modal
    }
}
</script>